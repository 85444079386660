import { getBarRadius } from '../../../reporting/utils/get-bar-radius';
import { animated } from 'react-spring';
import { Bar as VisxBar } from '@visx/shape';

interface BarProps {
  readonly x: number;
  readonly y: number;
  readonly width: number;
  readonly height: number;
  readonly opacity: number;
  readonly color: string;
}

const AnimatedBar = animated(VisxBar);

export function Bar({ x, y, width, height, color, opacity }: BarProps) {
  if (Number.isNaN(height) || height <= 0) {
    return null;
  }
  return (
    <AnimatedBar
      x={x}
      y={y}
      width={width}
      height={height}
      fill={color}
      opacity={opacity ?? 1}
      pointerEvents="none"
      rx={getBarRadius(width)}
    />
  );
}
