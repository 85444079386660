import { KeyResultStore } from './mobx/key-result-store';
import { ObjectiveStore } from './mobx/objective-store';
import { Objective } from './types';
import { UserStore } from '../users/mobx/user-store';
import { UsersStore } from '../users/mobx/users-store';

export function isValidKeyResult(keyResult: KeyResultStore): boolean {
  return keyResult.id !== null;
}

export function calculateObjectiveValidationScore(
  objective?: ObjectiveStore
): number {
  let score = 0;

  if (!objective) {
    return score;
  }

  if (objective.title) {
    score += 20;
  }

  if (objective.category) {
    score += 20;
  }

  if (objective.supervisors.length !== 0) {
    score += 20;
  }

  if (score < 60) {
    return score;
  }

  if (objective.description) {
    score += 10;
  }

  const validKeyResults = objective.keyResults;

  if (validKeyResults.length > 0) {
    score += 10;
  }

  if (validKeyResults.length > 1) {
    score += 10;
  }

  if (validKeyResults.length > 2) {
    score += 10;
  }

  return score;
}

export const OBJECTIVE_VALIDATION_CHECKS_COUNT = 6;

export function createObjectiveStore(
  objective: Objective,
  usersStore: UsersStore
): ObjectiveStore {
  const store = new ObjectiveStore();
  store
    .setId(objective.id)
    .setCategory(objective.category)
    .setStatus(objective.status ?? 'not-started')
    .setPriority(objective.priority ?? 'low')
    .setTitle(objective.title ?? '')
    .setDescription(objective.description ?? '')
    .setSupervisors(
      objective.supervisors
        ?.map(userId => usersStore.getUserById(userId))
        .filter((u): u is UserStore => Boolean(u)) ?? []
    )
    .addKeyResults(
      objective.keyResults.map(keyResult => {
        const resultStore = new KeyResultStore();
        resultStore
          .setId(keyResult.id)
          .setUid(keyResult.uid)
          .setTitle(keyResult.title)
          .setTargetDate(keyResult.targetDate)
          .setStatus(keyResult.status)
          .setMetric(keyResult.metric)
          .setStartValue(keyResult.startValue)
          .setTargetValue(keyResult.targetValue)
          .setCurrentValue(keyResult.currentValue)
          .setProgressValue(keyResult.progressValue)
          .setLastUpdateDate(keyResult.lastUpdated)
          .setSortCode(keyResult.sortCode);
        return resultStore;
      })
    )
    .sortKeyResults()
    .setActivities(objective.activities ?? [])
    .setEvaluations(objective.evaluations ?? []);

  return store;
}
