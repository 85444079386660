import { observer } from 'mobx-react-lite';
import { ObjectiveStore } from './mobx/objective-store';
import { ObjectiveEvaluation } from './types';
import { useCurrentUserStore, useUsersStore } from '../users/hooks';
import { useAddEvaluationHandler } from './hooks/use-add-evaluation-handler';
import {
  EvaluationAuthor,
  EvaluationAvatarWrapper,
  EvaluationLayout,
  EvaluationTextContainer,
  TooltipButtonWrapper
} from './components/evaluations';
import { RoleAvatar } from '../components-2/role-avatar';
import { RichTextEditor } from '../components/richtext';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { Button, ButtonAppearance } from '../components-2/button';
import { Icon, IconSize } from '@yarmill/components';
import { Tippy } from '../components/tippy/tippy';
import { isObjectiveFinished } from './utils/is-objective-finished';
import { Plus } from '@yarmill/icons-2';
import { Editor } from '@tiptap/react';

interface ObjectiveEvaluationProps {
  readonly objective: ObjectiveStore;
  readonly evaluation?: ObjectiveEvaluation;
  readonly isCurrentUsersEvaluation?: boolean;
}
export const ObjectiveEvaluationForm = observer(
  function ObjectiveEvaluationForm({
    objective,
    evaluation,
    isCurrentUsersEvaluation
  }: ObjectiveEvaluationProps) {
    const currentUser = useCurrentUserStore();
    const handleAddEvaluation = useAddEvaluationHandler(objective);
    const [editor, setEditor] = useState<Editor | null>(null);
    const usersStore = useUsersStore();
    const intl = useIntl();
    const user = usersStore.getUserById(
      evaluation ? evaluation.createdBy : currentUser.id
    );
    const evaluationText = evaluation?.evaluationText ?? '';
    const [showEditor, setShowEditor] = useState(
      Boolean(evaluation?.evaluationText)
    );
    const safariFakeInput = useRef<HTMLInputElement | null>(null);
    const [autoFocus, setAutoFocus] = useState(false);
    const formik = useFormik<{ evaluationText: string }>({
      initialValues: { evaluationText },
      onSubmit: async values => {
        if (!evaluation && !values.evaluationText) {
          return;
        }
        await handleAddEvaluation(values.evaluationText);
        objective.hasPendingUpdate = false;
      }
    });

    const submitFormDebounced = useRef(
      debounce(() => formik.submitForm(), 500)
    ).current;
    const submitForm = useCallback(() => {
      objective.hasPendingUpdate = true;
      submitFormDebounced();
    }, [objective, submitFormDebounced]);

    const setEvaluationText = useCallback(
      (value: string) => {
        let newValue = value;
        if (value === '<p></p>') {
          newValue = '';
        }
        formik.setFieldValue('evaluationText', newValue);

        submitForm();
      },
      [formik, submitForm]
    );

    const isButtonEnabled =
      isCurrentUsersEvaluation &&
      !evaluation?.evaluationText &&
      isObjectiveFinished(objective);

    const getEditor = useCallback((e: Editor | null) => {
      setEditor(e);
    }, []);

    useEffect(() => {
      if (editor && autoFocus) {
        setTimeout(() => {
          editor?.commands.focus('end');
          safariFakeInput.current?.remove();
        }, 0);
      }
    }, [editor, autoFocus]);

    if ((!evaluation && !isCurrentUsersEvaluation) || !user) {
      return null;
    }

    const isEditorWritable = Boolean(isCurrentUsersEvaluation);

    const onBlur = () => {
      if (!formik.values.evaluationText) {
        setShowEditor(false);
        if (evaluation) {
          objective.evaluations.remove(evaluation);
        }
      }
    };

    return (
      <EvaluationLayout showBorderBottom={showEditor}>
        <EvaluationAvatarWrapper>
          <Tippy tooltipContent={user.displayName} translateValue={false}>
            <RoleAvatar user={user} size={IconSize.s24} />
          </Tippy>
        </EvaluationAvatarWrapper>
        <EvaluationAuthor appearance="task13">
          {user.displayName}
        </EvaluationAuthor>
        <EvaluationTextContainer
          showEditor={showEditor}
          editable={isEditorWritable}
        >
          {showEditor ? (
            <RichTextEditor
              key={objective.uid}
              getEditor={getEditor}
              content={
                isEditorWritable
                  ? formik.values.evaluationText ?? ''
                  : evaluationText
              }
              disableFormatting
              onChange={isEditorWritable ? setEvaluationText : undefined}
              placeholder={intl.formatMessage({
                id: 'okrs.form.evaluation.placeholder'
              })}
              readOnly={!isEditorWritable}
              onBlur={onBlur}
              disableLazyEditor
            />
          ) : (
            <Tippy
              tooltipContent="okrs.addEvaluation.tooltip"
              isEnabled={!isButtonEnabled}
              noWrapper={isButtonEnabled}
              Wrapper={TooltipButtonWrapper}
            >
              <Button
                as="button"
                disabled={!isButtonEnabled}
                $appearance={ButtonAppearance.Secondary}
                $appearanceStyle="navy"
                onClick={
                  isButtonEnabled
                    ? () => {
                        setAutoFocus(true);
                        setShowEditor(true);
                        const fakeInput = document.createElement('input');
                        fakeInput.style.position = 'absolute';
                        fakeInput.style.top = '-1000px';
                        fakeInput.style.left = '-1000px';
                        safariFakeInput.current = fakeInput;
                        document.body.appendChild(fakeInput);
                        fakeInput.focus();
                      }
                    : undefined
                }
              >
                <Icon size={IconSize.s24}>
                  <Plus />
                </Icon>
                <FormattedMessage id={'okrs.addEvaluation'} />
              </Button>
            </Tippy>
          )}
        </EvaluationTextContainer>
      </EvaluationLayout>
    );
  }
);
