import { HttpLogger } from './http-logger';
import { LOG_LEVELS, LogLevel } from './types';
import { isLogLevelEnabled } from './is-log-level-enabled';

export class Logger {
  private readonly localStoragePersistKey = 'yarmill-log-level';
  private readonly localStorageHttpPersistKey = 'yarmill-http-logger';
  private readonly httpLogger?: HttpLogger;
  private httpAllowed: boolean;
  private level: LogLevel = 'warn';
  private readonly scope;

  public get currentLevel(): LogLevel {
    return this.level;
  }

  public set currentLevel(level: LogLevel) {
    this.level = level;
    this.initAndPersist();
  }

  public disableHttpLogger(): void {
    this.httpAllowed = false;
  }

  public enableHttpLogger(): void {
    this.httpAllowed = true;
  }

  public createScopedLogger(scope: string): Logger {
    return new Logger(scope, this.httpLogger);
  }

  public constructor(scope: string = 'global', httpLogger?: HttpLogger) {
    this.httpLogger = httpLogger ?? new HttpLogger();
    this.httpAllowed = true;
    this.scope = scope;
    this.loadAndInitConfigs();
    this.addToGlobalNamespace();
  }

  public log(_message?: any, ..._optionalParams: any[]): void {
    console.log(_message, ..._optionalParams);
  }
  public debug(message?: any, ...optionalParams: any[]): void {
    if (isLogLevelEnabled('debug', this.level)) {
      console.debug(message, ...optionalParams);
    }
    this.logHttp(message, 'debug', ...optionalParams);
  }
  public info(message?: any, ...optionalParams: any[]): void {
    if (isLogLevelEnabled('info', this.level)) {
      console.info(message, ...optionalParams);
    }
    this.logHttp(message, 'info', ...optionalParams);
  }
  public warn(message?: any, ...optionalParams: any[]): void {
    if (isLogLevelEnabled('warn', this.level)) {
      console.warn(message, ...optionalParams);
    }
    this.logHttp(message, 'warn', ...optionalParams);
  }
  public error(message?: any, ...optionalParams: any[]): void {
    if (isLogLevelEnabled('error', this.level)) {
      console.error(message, ...optionalParams);
    }
    this.logHttp(message, 'error', ...optionalParams);
  }
  public trace(message?: any, ...optionalParams: any[]): void {
    if (isLogLevelEnabled('debug', this.level)) {
      console.trace(message, ...optionalParams);
    }
  }

  public levelError(): void {
    this.currentLevel = 'error';
  }
  public levelWarning(): void {
    this.currentLevel = 'warn';
  }
  public levelInfo(): void {
    this.currentLevel = 'info';
  }
  public levelDebug(): void {
    this.currentLevel = 'debug';
  }

  private loadAndInitConfigs = (): void => {
    const storageLevel = window?.localStorage?.getItem(this.persistKey);
    if (storageLevel && LOG_LEVELS.indexOf(storageLevel as LogLevel) >= 0) {
      this.level = storageLevel as LogLevel;
    }
    const httpAllowed = window?.localStorage.getItem(
      this.localStorageHttpPersistKey
    );
    if (httpAllowed !== null) {
      this.httpAllowed = httpAllowed !== 'false';
    }
  };

  private get persistKey(): string {
    return `${this.localStoragePersistKey}-${this.scope}`;
  }

  private initAndPersist(): void {
    window?.localStorage.setItem(this.persistKey, this.level);
  }

  private addToGlobalNamespace(): void {
    if (window) {
      window['ytd']['log'][this.scope] = this;
      window['ytd']['log']['http'] = this.httpLogger;
    }
  }

  private logHttp(
    message: string,
    level: LogLevel,
    ...optionalParams: any[]
  ): void {
    if (this.httpAllowed) {
      this.httpLogger?.sendHttp(message, level, ...optionalParams);
    }
  }
}
