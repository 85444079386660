import * as React from 'react';
import { useFileUploadService } from '../hooks';
import { toastErrorFileUpload, toastShowFileUpload } from '../file-upload';
import { FileUploadStore } from '../mobx/file-upload-store';
import { AsyncStatus } from '@yarmill/components';
import { Id } from 'react-toastify';
import { useLogger } from '../../utils/logger/logger-context';

type FileUploadCallback = (store: FileUploadStore, toastId: Id) => void;
export function useFileUpload(
  onSuccess?: FileUploadCallback,
  onError?: FileUploadCallback,
  onStart?: FileUploadCallback
): [React.RefObject<HTMLInputElement>, React.ChangeEventHandler] {
  const fileUploadService = useFileUploadService();
  const logger = useLogger();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onChange = React.useCallback(() => {
    if (inputRef.current) {
      const input = inputRef.current;
      if (input.files) {
        for (const file of input.files) {
          const fileUpload = fileUploadService.uploadFile(file);
          const toastId = toastShowFileUpload(fileUpload);
          fileUpload.setToastId(toastId);
          onStart?.(fileUpload, toastId);

          fileUpload
            .uploadFile()
            .then(() => {
              if (
                fileUpload.status === AsyncStatus.resolved &&
                fileUpload.uploadedFile
              ) {
                onSuccess?.(fileUpload, toastId);
              } else {
                if (onError) {
                  onError?.(fileUpload, toastId);
                } else {
                  toastErrorFileUpload(toastId);
                }
              }
            })
            .catch(e => {
              logger.error('Unable to upload file.', e);
              if (onError) {
                onError?.(fileUpload, toastId);
              } else {
                toastErrorFileUpload(toastId);
              }
            });
        }
        input.value = '';
      }
    }
  }, [fileUploadService, onStart, onSuccess, onError, logger]);

  return [inputRef, onChange];
}
