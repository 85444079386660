import { useCalculateTopPadding } from './hooks/use-calculate-top-padding';
import { useScale } from './hooks/use-scale';
import { useXAxisHeight } from './hooks/use-x-axis-height';
import { useMarkerPadding } from './hooks/use-marker-padding';
import { useMemo } from 'react';
import { useCalculateLeftPadding } from './hooks/use-calculate-left-padding';
import { useCalculateRightPadding } from './hooks/use-calculate-right-padding';
import styled from 'styled-components';
import { XYChartContext } from './xy-chart-context';
import { GridLines } from './grid-lines';
import { Axes } from './axes';
import { ChartElements } from './chart-elements';
import {
  AxisConfig,
  AxisValue,
  DataItem,
  Domain,
  GetTooltipData,
  GridLinesConfig,
  XAxisConfig,
  YAxisConfig
} from '../reporting/types';
import { ChartConfig } from './types';
import { Tooltip } from './tooltip';
import { TooltipContext, useTooltip } from './hooks/use-tooltip';
import { Group } from '@visx/group';
import { RangeMask } from './range-mask';
import { DataLabels } from './data-labels';
import { BackgroundImage } from './background-image';

export * from './types';

interface XyChartProps {
  readonly axisConfigs: AxisConfig<AxisValue, AxisValue>[];
  readonly axisYTicks: number[] | undefined;
  readonly code: string;
  readonly configs: ChartConfig[];
  readonly data: DataItem[];
  readonly domainX: Domain;
  readonly domainY: Domain;
  readonly getTooltipData: GetTooltipData;
  readonly gridLinesColumnsTicks?: AxisValue[];
  readonly gridLinesConfig?: GridLinesConfig;
  readonly gridLinesRowsTicks?: AxisValue[];
  readonly hideTooltip?: boolean;
  readonly height: number;
  readonly width: number;
  readonly backgroundImage?: string;
}

const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export function XyChart(props: XyChartProps) {
  const {
    axisConfigs,
    axisYTicks,
    code,
    configs,
    data,
    domainX,
    domainY,
    getTooltipData,
    gridLinesColumnsTicks,
    gridLinesConfig,
    gridLinesRowsTicks,
    height,
    width,
    backgroundImage,
    hideTooltip
  } = props;

  const [containerRef, tooltipContextValue] = useTooltip(getTooltipData);

  const xAxisConfig = axisConfigs.find(
    (axis): axis is XAxisConfig<AxisValue> => axis.axis === 'x'
  );
  const yAxisConfig = axisConfigs.find(
    (axis): axis is YAxisConfig<AxisValue> => axis.axis === 'y'
  );

  const yScale = useScale(domainY, yAxisConfig?.type ?? 'continuous');
  const xScale = useScale(domainX, xAxisConfig?.type ?? 'continuous');

  const xAxisHeight = useXAxisHeight(xScale, xAxisConfig);
  const markerPadding = useMarkerPadding(configs, data);
  const paddingTop = useCalculateTopPadding(configs, data, yAxisConfig);

  const paddingBottom = xAxisHeight + markerPadding;
  const paddingLeft = useCalculateLeftPadding(
    yScale,
    xScale,
    yAxisConfig,
    xAxisConfig,
    markerPadding,
    configs,
    data
  );
  const paddingRight = useCalculateRightPadding(
    xScale,
    xAxisConfig,
    markerPadding,
    configs,
    data
  );

  const yMax = height - paddingBottom;
  const xMax = width - paddingRight;

  useMemo(() => {
    yScale.range([yMax, paddingTop]);
  }, [yScale, yMax, paddingTop]);

  useMemo(() => {
    xScale.range([paddingLeft, xMax]);
  }, [xScale, paddingLeft, xMax]);

  const xyChartContextValue = useMemo(
    () => ({
      xScale,
      yScale,
      chartRect: {
        top: paddingTop,
        left: paddingLeft,
        right: xMax,
        bottom: yMax
      }
    }),
    [paddingLeft, paddingTop, xMax, xScale, yMax, yScale]
  );

  return (
    <XYChartContext.Provider value={xyChartContextValue}>
      <TooltipContext.Provider value={tooltipContextValue}>
        <ChartWrapper>
          <svg width={width} height={height} ref={containerRef}>
            <RangeMask code={code} markerPadding={markerPadding} />
            <BackgroundImage image={backgroundImage} />
            <GridLines
              config={gridLinesConfig}
              columnTicks={gridLinesColumnsTicks}
              rowTicks={gridLinesRowsTicks}
            />
            <Axes
              xAxisConfig={xAxisConfig}
              yAxisConfig={yAxisConfig}
              yAxisTicks={axisYTicks}
            />
            {!hideTooltip && <Tooltip />}
            <Group mask={`url(#${code})`}>
              <ChartElements configs={configs} data={data} />
            </Group>
            <DataLabels configs={configs} data={data} />
          </svg>
        </ChartWrapper>
      </TooltipContext.Provider>
    </XYChartContext.Provider>
  );
}
