import {
  ExternalIcon,
  Icon,
  IconSize,
  PopoverContentWrapper,
  PopoverIconWrapper,
  StyledTd,
  StyledTr,
  Text,
  TextSize,
  TextTag,
  WhiteSpace
} from '@yarmill/components';
import moment from 'moment';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { EllipsisButton } from '../components/ellipsis-button';
import { Popover } from '../components/popover';
import { PopoverItem } from '../components/popover/popover-item';
import {
  trackDeactivateUserCancelClick,
  trackDeactivateUserClick,
  trackDeactivateUserConfirmClick,
  trackReactivateUserCancelClick,
  trackReactivateUserClick,
  trackReactivateUserConfirmClick,
  trackResendInvitationCancelClick,
  trackResendInvitationClick,
  trackResendInvitationConfirmClick,
  trackUsersShowUsersDetailsClick
} from '../google-analytics/utils';
import { UserAccountState } from './types';
import { Avatar } from '../profile/avatar';
import { UserStore } from './mobx/user-store';
import { useCurrentUserStore, useUsersStore } from './hooks';
import { observer } from 'mobx-react-lite';
import { toast } from '../components/toast-message';
import { Link } from '../components/link';
import { RoleTag } from '../components/role-tag';
import { CSSProperties } from 'react';
import { useLayer } from '../layer-manager/hooks';
import { LayerPortal } from '../layer-manager/layer-portal';
import { UpdateEmailDialog } from './update-email-dialog';
import { useLogger } from '../utils/logger/logger-context';

export interface UserRowProps {
  readonly user: UserStore;
  readonly isVirtualized?: boolean;
  readonly style?: CSSProperties;
}

const Td = styled(StyledTd)<{ disabled: boolean; isVirtualized?: boolean }>`
  transition: opacity 250ms ease-in;
  color: #4a4a4a;
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #ffffff;
      color: rgba(74, 74, 74, 0.3);
      img {
        opacity: 0.3;
      }
    `};

  ${({ isVirtualized }) =>
    isVirtualized &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
    `};
`;

const DateTd = styled(Td)`
  white-space: nowrap;
`;

const GroupsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px;
`;

const GroupRow = styled.div`
  display: flex;
  align-items: center;
`;

const Tr = styled(StyledTr)<{ readonly isVirtualized?: boolean }>`
  ${({ isVirtualized }) =>
    isVirtualized &&
    css`
      display: grid;
      align-content: center;
      grid-template-columns: 40px 60vw 116px 150px 60px 80px 80px 60px;

      @media (min-width: 560px) {
        grid-template-columns: 40px 1fr 116px 150px 60px 80px 80px 60px;
      }

      & > div {
        display: flex;
        align-content: center;
      }
    `}
`;

export const UserRow = observer(function UserRow(
  props: UserRowProps
): JSX.Element {
  const { user, isVirtualized, style } = props;
  const intl = useIntl();
  const currentUser = useCurrentUserStore();
  const usersStore = useUsersStore();
  const logger = useLogger();
  const updateEmailLayer = useLayer('alert', {
    showShim: true,
    closeOnShimClick: true
  });
  const showDisabledState =
    user.internalUser.AccountStateId === UserAccountState.CANCELED;

  const handleDeleteUser = async (): Promise<void> => {
    trackDeactivateUserClick(user.displayName);

    const message = intl.formatMessage(
      { id: 'settings.users.deactivateUserConfirm' },
      { user: user.displayName }
    );
    if (window.confirm(message)) {
      trackDeactivateUserConfirmClick(user.displayName);
      const success = await user.delete();
      if (success) {
        toast('toast.success.deactivateUser', 'success', {
          user: user.displayName
        });
      } else {
        toast('toast.error.deactivateUser', 'error', {
          user: user.displayName
        });
      }
    } else {
      trackDeactivateUserCancelClick(user.displayName);
    }
  };

  const handleResendInvitation = async (): Promise<boolean> => {
    trackResendInvitationClick(user.displayName);
    const message = intl.formatMessage(
      { id: 'settings.users.resendInvitationConfirm' },
      { user: user.displayName }
    );

    if (window.confirm(message)) {
      trackResendInvitationConfirmClick(user.displayName);
      const success = await user.resendInvitation();
      if (success) {
        toast('toast.success.resendInvitation', 'success', {
          user: user.displayName
        });
      } else {
        toast('toast.error.resendInvitation', 'error', {
          user: user.displayName
        });
      }

      return success;
    }

    trackResendInvitationCancelClick(user.displayName);

    return false;
  };

  const handleReactivateUser = async (): Promise<boolean> => {
    trackReactivateUserClick(user.displayName);

    const message = intl.formatMessage(
      { id: 'settings.users.reactivateUserConfirm' },
      { user: user.displayName }
    );
    if (window.confirm(message)) {
      const result = await usersStore.reactivateUser(user.id);
      trackReactivateUserConfirmClick(user.displayName);
      if (result) {
        toast('toast.success.reactivateUser', 'success', {
          user: user.displayName
        });
      } else {
        toast('toast.error.reactivateUser', 'error', {
          user: user.displayName
        });
      }

      return result;
    } else {
      trackReactivateUserCancelClick(user.displayName);
      return true;
    }
  };

  const handleImpersonateUser = async (): Promise<void> => {
    try {
      await usersStore.impersonateUser(user.id);
      window.location.href = '/';
    } catch (e) {
      logger.error('Impersonalization failed', e);
      toast('toast.error.impersonateUser', 'error', {
        user: user.displayName
      });
    }
  };

  return (
    <>
      <Tr
        isVirtualized={isVirtualized}
        as={isVirtualized ? 'div' : undefined}
        style={style}
      >
        <Td disabled={showDisabledState} as={isVirtualized ? 'div' : undefined}>
          <Avatar id={user.avatar} alt={user.displayName} />
        </Td>
        <Td
          disabled={showDisabledState}
          align="left"
          as={isVirtualized ? 'div' : undefined}
          isVirtualized={isVirtualized}
        >
          <Text
            tag={TextTag.div}
            bold
            size={TextSize.s12}
            whiteSpace={WhiteSpace.noWrap}
            inheritColor
          >
            {user.displayName}
          </Text>
          <Text
            tag={TextTag.div}
            size={TextSize.s12}
            whiteSpace={WhiteSpace.noWrap}
            inheritColor
          >
            {user.internalUser.Email}
          </Text>
        </Td>
        <Td
          as={isVirtualized ? 'div' : undefined}
          disabled={false}
          isVirtualized={isVirtualized}
        >
          <RoleTag role={user.internalUser.Role} />
        </Td>
        <Td
          as={isVirtualized ? 'div' : undefined}
          disabled={false}
          isVirtualized={isVirtualized}
        >
          <Text
            size={TextSize.s12}
            tag={TextTag.div}
            whiteSpace={WhiteSpace.noWrap}
          >
            <FormattedMessage
              id={`settings.users.accountState.${user.internalUser.AccountStateId}`}
            />
          </Text>
          {user.internalUser.AccountStateId === UserAccountState.PENDING &&
            user.internalUser.TokenValidity !== null && (
              <>
                <Text
                  size={TextSize.s10}
                  tag={TextTag.div}
                  whiteSpace={WhiteSpace.noWrap}
                >
                  <FormattedMessage id="settings.users.accountState.pending.validUntil" />
                  &nbsp;
                  <FormattedDate
                    value={moment(user.internalUser.TokenValidity).toDate()}
                    day="numeric"
                    month="numeric"
                    year="numeric"
                  />
                </Text>
              </>
            )}
        </Td>
        <Td
          as={isVirtualized ? 'div' : undefined}
          disabled={false}
          isVirtualized={isVirtualized}
        >
          <Popover
            autoWidth
            trigger="mouseenter"
            disabled={user.groups.length === 0}
            content={() => (
              <GroupsList>
                {user.groups.map(group => (
                  <GroupRow key={group.id}>
                    <Text size={TextSize.s14}>
                      <Link to={`/settings/groups/${group.id}`}>
                        {group.name}
                      </Link>
                    </Text>
                  </GroupRow>
                ))}
              </GroupsList>
            )}
          >
            <span>
              <Text size={TextSize.s12}>{user.groups.length}</Text>
            </span>
          </Popover>
        </Td>
        <DateTd
          as={isVirtualized ? 'div' : undefined}
          disabled={false}
          isVirtualized={isVirtualized}
        >
          {user.internalUser.MemberSince && (
            <Text size={TextSize.s12} whiteSpace={WhiteSpace.noWrap}>
              <FormattedDate
                value={moment(user.internalUser.MemberSince).toDate()}
                day="numeric"
                month="numeric"
                year="numeric"
              />
            </Text>
          )}
        </DateTd>
        <DateTd
          as={isVirtualized ? 'div' : undefined}
          disabled={false}
          isVirtualized={isVirtualized}
        >
          {user.internalUser.MemberUntil && (
            <Text size={TextSize.s12} whiteSpace={WhiteSpace.noWrap}>
              <FormattedDate
                value={moment(user.internalUser.MemberUntil).toDate()}
                day="numeric"
                month="numeric"
                year="numeric"
              />
            </Text>
          )}
        </DateTd>
        <StyledTd as={isVirtualized ? 'div' : undefined}>
          <Popover
            content={tippy => (
              <>
                <PopoverItem
                  to={
                    currentUser && currentUser.id === user.internalUser.UserId
                      ? '/settings/personal'
                      : `/settings/users/${user.id}`
                  }
                  onClick={() =>
                    trackUsersShowUsersDetailsClick(user.displayName)
                  }
                >
                  <PopoverIconWrapper>
                    <Icon size={IconSize.s20}>
                      <ExternalIcon name="UserCircle" />
                    </Icon>
                  </PopoverIconWrapper>
                  <PopoverContentWrapper>
                    <strong>
                      <FormattedMessage id="settings.users.memberDetail" />
                    </strong>
                    <div>
                      <FormattedMessage id="settings.users.memberDetailHelp" />
                    </div>
                  </PopoverContentWrapper>
                </PopoverItem>

                {currentUser.isAllowedTo('settings.users.updateEmail') && (
                  <PopoverItem
                    onClick={async () => {
                      if (!updateEmailLayer.isOpened) {
                        updateEmailLayer.open();
                      }
                      tippy.hide();
                    }}
                  >
                    <PopoverIconWrapper>
                      <Icon size={IconSize.s20}>
                        <ExternalIcon name="At" />
                      </Icon>
                    </PopoverIconWrapper>
                    <PopoverContentWrapper>
                      <strong>
                        <FormattedMessage
                          id="settings.users.updateEmail"
                          values={{
                            user: user.displayName
                          }}
                        />
                      </strong>
                      <div>
                        <FormattedMessage
                          id="settings.users.updateEmailHelp"
                          values={{
                            user: user.displayName
                          }}
                        />
                      </div>
                    </PopoverContentWrapper>
                  </PopoverItem>
                )}

                {user.internalUser.AccountStateId ===
                  UserAccountState.PENDING && (
                  <PopoverItem
                    onClick={async () =>
                      (await handleResendInvitation()) && tippy.hide()
                    }
                  >
                    <PopoverIconWrapper>
                      <Icon size={IconSize.s20}>
                        <ExternalIcon name="Repeat" />
                      </Icon>
                    </PopoverIconWrapper>
                    <PopoverContentWrapper>
                      <strong>
                        <FormattedMessage id="settings.users.resendInvitation" />
                      </strong>
                      <div>
                        <FormattedMessage id="settings.users.resendInvitationHelp" />
                      </div>
                    </PopoverContentWrapper>
                  </PopoverItem>
                )}

                {currentUser.role === 'admin' &&
                  user.internalUser.AccountStateId !==
                    UserAccountState.CANCELED && (
                    <PopoverItem
                      appearance="danger"
                      onClick={async () => {
                        void handleDeleteUser();
                        tippy.hide();
                      }}
                    >
                      <PopoverIconWrapper>
                        <Icon size={IconSize.s20}>
                          <ExternalIcon name="CircleX" />
                        </Icon>
                      </PopoverIconWrapper>
                      <PopoverContentWrapper>
                        <strong>
                          <FormattedMessage id="settings.users.deactivateUser" />
                        </strong>
                        <div>
                          <FormattedMessage id="settings.users.deactivateUserHelp" />
                        </div>
                      </PopoverContentWrapper>
                    </PopoverItem>
                  )}

                {currentUser.role === 'admin' &&
                  user.internalUser.AccountStateId ===
                    UserAccountState.CANCELED && (
                    <PopoverItem
                      onClick={async () => {
                        void handleReactivateUser();
                        tippy.hide();
                      }}
                    >
                      <PopoverIconWrapper>
                        <Icon size={IconSize.s20}>
                          <ExternalIcon name="CirclePlus" />
                        </Icon>
                      </PopoverIconWrapper>
                      <PopoverContentWrapper>
                        <strong>
                          <FormattedMessage id="settings.users.reactivateUser" />
                        </strong>
                        <div>
                          <FormattedMessage id="settings.users.reactivateUserHelp" />
                        </div>
                      </PopoverContentWrapper>
                    </PopoverItem>
                  )}

                {currentUser.isAllowedTo('app.impersonate') &&
                  user.internalUser.AccountStateId ===
                    UserAccountState.ACTIVE && (
                    <PopoverItem
                      onClick={async () => {
                        void handleImpersonateUser();
                        tippy.hide();
                      }}
                    >
                      <PopoverIconWrapper>
                        <Icon size={IconSize.s20}>
                          <ExternalIcon name="Users" />
                        </Icon>
                      </PopoverIconWrapper>
                      <PopoverContentWrapper>
                        <strong>
                          <FormattedMessage
                            id="settings.users.impersonateUser"
                            values={{
                              user: user.displayName
                            }}
                          />
                        </strong>
                        <div>
                          <FormattedMessage
                            id="settings.users.impersonateUserHelp"
                            values={{
                              user: user.displayName
                            }}
                          />
                        </div>
                      </PopoverContentWrapper>
                    </PopoverItem>
                  )}
              </>
            )}
          >
            <EllipsisButton />
          </Popover>
        </StyledTd>
      </Tr>
      <LayerPortal
        layerHandle={updateEmailLayer}
        getContent={layer => (
          <UpdateEmailDialog close={layer.close} user={user} />
        )}
      />
    </>
  );
});
