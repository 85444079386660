import { styled } from '@yarmill/components';
import { getTextAppearanceStyles, Text } from '../../text';
import { Button } from '../../button';
import { RichTextIconWrapper, TextArea } from '../../textarea';

export const ObjectiveDetailLayout = styled.form`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.size.x2};
`;

export const ObjectiveTitle = styled(TextArea)`
  color: ${({ theme }) => theme.color.neutralDark};
`;

export const ObjectiveDescription = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.size.x05} 0;

  :hover {
    background-color: ${({ theme }) => theme.color.neutralPlate};
  }

  :focus-within {
    background-color: ${({ theme }) => theme.color.neutralPlate};

    ${RichTextIconWrapper} {
      color: ${({ theme }) => theme.color.navy};
    }
  }
`;

export const ObjectiveDescriptionRichtextWrapper = styled.div`
  padding-left: ${({ theme }) => theme.size.x4};
  padding-right: ${({ theme }) => theme.size.x1};
  color: ${({ theme }) => theme.color.neutral};
  ${getTextAppearanceStyles('text15')};
  border-radius: ${({ theme }) => theme.borderRadius.x1};

  :focus-within {
    color: ${({ theme }) => theme.color.neutralDark};
  }
`;

export const ObjectiveDetailAttributesLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.size.x1};
  padding: 0 ${({ theme }) => theme.size.x4};
`;

export const ObjectiveActivityHistoryLabel = styled(Text)`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.neutral};
`;
export const ObjectiveActivityHistoryLayout = styled.div`
  padding: 0 ${({ theme }) => theme.size.x4};
  overflow: auto;
`;

export const ObjectiveActivityItemAvatar = styled.div`
  width: ${({ theme }) => theme.size.x2};
  height: ${({ theme }) => theme.size.x2};
`;

export const ObjectiveActivityItemLayout = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.size.x1};
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.size.x2};
`;

export const ObjectiveActivityItemText = styled(Text)`
  color: ${({ theme }) => theme.color.neutral};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const ObjectiveActivityItemDateText = styled(Text)`
  color: ${({ theme }) => theme.color.neutral};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
`;

export const BackButton = styled(Button)`
  @media (min-width: 768px) {
    display: none;
  }
`;
