import { MouseEvent, useCallback } from 'react';
import { toast } from '../../components/toast-message';
import { useYollandaService } from './use-yollanda-service';
import { YollandaInteractionId } from '../types';
import { useLogger } from '../../utils/logger/logger-context';

export function useSendFeedbackHandler(interactionId: YollandaInteractionId) {
  const yollandaService = useYollandaService();
  const logger = useLogger();
  return useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      if (e.target instanceof HTMLButtonElement) {
        const qualityResponse = e.target.value;

        try {
          const response = await yollandaService.sendFeedback(
            interactionId,
            Number(qualityResponse)
          );
          if (response.status >= 200 && response.status < 300) {
            toast('toast.success.yollandaFeedback', 'success', {
              qualityResponse
            });
          } else {
            toast('toast.error.yollandaFeedback', 'error', {
              qualityResponse
            });
          }
        } catch (e) {
          logger.error('Sending yollanda feedback failed', e);
          toast('toast.error.yollandaFeedback', 'error', {
            qualityResponse
          });
        }
      }
    },
    [interactionId, yollandaService]
  );
}
