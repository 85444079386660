import { useScale } from './use-scale';
import { useMemo } from 'react';
import { AxisValue, DataItem, XAxisConfig } from '../../reporting/types';
import { getSSRStringWidth } from '../../reporting/utils/get-ssr-string-width';
import { ChartConfig } from '@yarmill/components';

export function useCalculateRightPadding(
  xScale: ReturnType<typeof useScale>,
  xAxisConfig: XAxisConfig<AxisValue> | undefined,
  markerPadding: number,
  chartConfigs: ChartConfig[],
  data: DataItem[]
) {
  return useMemo(() => {
    const isContinuous = 'ticks' in xScale;
    let xTickWidth = 0;
    if (xAxisConfig?.showTickLabels && isContinuous) {
      const xTicks = xScale.ticks();
      const lastTick = xTicks[xTicks.length - 1];
      const value = xAxisConfig.formatTick(lastTick);
      xTickWidth = getSSRStringWidth(value) / 2;
    }

    const labelWidth = isContinuous
      ? Math.max(
          ...chartConfigs.map(config => {
            if (!('getYValue' in config)) {
              return 0;
            }
            const lastItem = data.slice().reverse().find(Boolean);
            if (!lastItem) {
              return 0;
            }
            if (!config.getShowLabels(lastItem)) {
              return 0;
            }
            const value = config.getYValue(lastItem);
            const label = config.formatLabelValue(value);

            return getSSRStringWidth(label) / 2;
          })
        )
      : 0;

    const marker =
      isContinuous || markerPadding > xScale.bandwidth() ? markerPadding : 0;

    return Math.max(xTickWidth, marker, labelWidth);
  }, [chartConfigs, data, markerPadding, xAxisConfig, xScale]);
}
