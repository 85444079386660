import {
  BusinessFormatterOptions,
  DataDefinitionV2,
  DataSource,
  ReportingDataBusinessFormat
} from './types';
import {
  ExternalIcon,
  ExternalIconName,
  isBrowser,
  Text,
  TextSize,
  WhiteSpace
} from '@yarmill/components';
import moment from 'moment';
import { formatNumber } from '../utils/format-number';
import { ReactNode } from 'react';
import { getDataFormatter } from './data-formats';
import { PermissionScope } from '../permissions/types';
import { UserStore } from '../users/mobx/user-store';
import { User } from '../users/types';
import { bisector } from 'd3-array';
import { RootStore } from '../app/mobx/root-store';

export const PRINT_WIDTH = 960;
export function createBarGroupLabelFormatter(
  bars: Record<string, DataDefinitionV2>
): (value?: number, key?: string) => string {
  return (value?: number, key?: string) => {
    if (!key) {
      return '';
    }

    const bar = bars[key];
    return formatValueByBusinessFormat(
      value,
      bar?.BusinessFormat,
      bar?.Format || null,
      { forceString: true }
    ) as string;
  };
}

export function createLabelFormatter(
  businessFormat: ReportingDataBusinessFormat,
  format: string | number | null
): (value?: number) => string {
  return (value?: number) =>
    formatValueByBusinessFormat(value, businessFormat, format, {
      forceString: true
    }) as string;
}

export function mapColor(color: string): string {
  switch (color) {
    case 'crayola':
      return 'rgba(26, 130, 188, 1)';
    case 'blue':
      return 'rgba(215, 233, 246, 1)';
    case 'yellow':
      return 'rgba(251, 190, 74, 1)';
    case 'tomato':
      return 'rgba(249, 109, 81, 1)';
    case 'red':
      return 'rgba(252, 29, 92, 1)';
    case 'pink':
      return 'rgba(209, 46, 140, 1)';
    case 'grape':
      return 'rgba(91, 75, 139, 1)';
    default:
      return color;
  }
}

export function formatValueByBusinessFormat(
  value: string | number | null | undefined,
  dataType: ReportingDataBusinessFormat | undefined,
  format: string | number | null,
  options?: BusinessFormatterOptions
): ReactNode {
  if (value === null || value === undefined) {
    return '';
  }

  const numberValue = Number(value);

  switch (dataType) {
    case 'number':
      return formatNumber(numberValue);
    case 'decimalNumber':
      return numberValue.toLocaleString('cs', {
        maximumFractionDigits:
          format !== null && format !== undefined ? Number(format) : 2,
        minimumFractionDigits:
          format !== null && format !== undefined ? Number(format) : 2
      });
    case 'percent':
      return `${Math.round(numberValue * 100)}%`;
    case 'duration':
      const duration = moment
        .duration(Number(value), 'seconds')
        .format(format ? String(format) : 'hh:mm', { trim: false });

      if (options?.forceString) {
        return duration;
      }
      return (
        <Text
          whiteSpace={WhiteSpace.noWrap}
          size={TextSize.inherit}
          monoSpace={options?.monoSpace}
        >
          {duration}
        </Text>
      );
    case 'date':
      const formattedDate = moment(String(value)).format(
        format ? String(format) : undefined
      );
      if (options?.forceString) {
        return formattedDate;
      }
      return (
        <Text
          whiteSpace={WhiteSpace.noWrap}
          size={TextSize.inherit}
          monoSpace={options?.monoSpace}
        >
          {formattedDate}
        </Text>
      );
    case 'richtext':
    case 'athleteWithAvatar':
    case 'shortDateDay':
    case 'weekday':
    case 'icon':
      return getDataFormatter(dataType, options)(value);
    case undefined:
    default:
      return value;
  }
}

export function getIcon(
  name?: ExternalIconName | null,
  defaultValue: JSX.Element = <></>
): JSX.Element {
  if (name) {
    return <ExternalIcon name={name} />;
  }

  return defaultValue;
}

export function getReportingPagePermissionScope(
  pageCode: string
): PermissionScope {
  return `reporting.${pageCode}`;
}

export function getDataSourceItemId(
  item: DataSource['Data'][0]
): string | number {
  if ('UserId' in item) {
    return item.UserId;
  }
  if ('UserGroupId' in item) {
    return item.UserGroupId;
  }
  if ('SeasonId' in item) {
    return item.SeasonId;
  }
  if ('ActivityItemId' in item) {
    return item.ActivityItemId;
  }
  if ('AttributeItemId' in item) {
    return item.AttributeItemId;
  }

  return -1;
}

export function createDataSourceItem(
  rootStore: RootStore,
  item: DataSource['Data'][0]
): UserStore | Exclude<DataSource['Data'][0], User> {
  if ('UserId' in item) {
    return new UserStore(rootStore, item);
  }
  return item;
}

function countOccurrences(array: string[], value: string) {
  return array.reduce((count, i) => (i === value ? count + 1 : count), 0);
}

export function calculateSectionRatio(
  sectionLayout: string,
  reportCode: string
): number {
  const positions = sectionLayout.split(' ').filter(Boolean);

  if (positions.length === 0) {
    return 1;
  }

  return countOccurrences(positions, reportCode) / positions.length;
}

export function fillStringTemplate(
  stringTemplate: string,
  replacements: Record<string, string | number | boolean>
): string {
  let replacedString = stringTemplate;
  Object.keys(replacements).forEach(key => {
    replacedString = replacedString.replace(
      new RegExp('\\${' + key + '}', 'g'),
      String(replacements[key])
    );
  });

  return replacedString;
}

export function findClosetsTimeItem<T extends object>(
  data: T[],
  key: keyof T,
  date: Date,
  tolerance: number
): T | null {
  const bisectDate = bisector<T, Date>(d =>
    moment(d[key] as string).toDate()
  ).left;

  const index = bisectDate(data, date);
  const d0 = data[index - 1];
  const d1 = data[index];
  let closest = d0;
  const xValueDate = date.valueOf();

  if (!d0 && d1) {
    closest = d1;
  } else if (d0 && !d1) {
    closest = d0;
  }

  if (d0 && d1) {
    closest =
      xValueDate -
        moment(d0[key] as string)
          .toDate()
          .valueOf() >
      moment(d1[key] as string).valueOf() - xValueDate
        ? d1
        : d0;
  }

  const min = xValueDate - tolerance;
  const max = xValueDate + tolerance;
  const itemDate = moment(closest[key] as string)
    .toDate()
    .valueOf();

  return itemDate >= min && itemDate <= max ? closest : null;
}

export function findClosetsNumericItem<T extends object>(
  data: T[],
  key: keyof T,
  value: number,
  tolerance: number
): T | null {
  const bisectDate = bisector<T, number>(d => d[key] as number).left;

  const index = bisectDate(data, value);
  const d0 = data[index - 1];
  const d1 = data[index];
  let closest = d0;

  if (!d0 && d1) {
    closest = d1;
  } else if (d0 && !d1) {
    closest = d0;
  }

  if (d0 && d1) {
    closest =
      value - (d0[key] as number) > (d1[key] as number) - value ? d1 : d0;
  }

  const min = value - tolerance;
  const max = value + tolerance;
  const itemValue = closest[key];

  return itemValue >= min && itemValue <= max ? closest : null;
}

export function getReportWidth(width: number, sectionRatio?: number): number {
  return isBrowser ? width : PRINT_WIDTH * (sectionRatio || 1);
}
