export type LogLevel = 'debug' | 'info' | 'warn' | 'error';
export const LOG_LEVELS: LogLevel[] = ['debug', 'info', 'warn', 'error'];

export interface LogEntry {
  timestamp: number;
  scope: string;
  level: string;
  message: string;
  url: string;
  language: string;
  instanceCode: string;
  agent?: string;
  windowWidth?: number;
  windowHeight?: number;
  appVersion: string;
}
