import { LinkProps } from 'react-router-dom';
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  PropsWithChildren
} from 'react';
import { css, styled } from '@yarmill/components';
import { getAppearanceColors, TextInputProps } from './text-input';
import { Link } from '../components/link';
import { getTextAppearanceStyles } from './text';

export enum ButtonAppearance {
  Primary,
  Secondary,
  Tertiary,
  None
}
type ButtonSize = 'compact' | 'default';
type ButtonButtonProps = PropsWithChildren<
  StyledButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>;

type ButtonLinkProps = PropsWithChildren<
  StyledButtonProps & AnchorHTMLAttributes<HTMLAnchorElement>
>;

type RouterButtonLinkProps = ButtonLinkProps & LinkProps;

export type ButtonProps = (
  | ButtonButtonProps
  | ButtonLinkProps
  | RouterButtonLinkProps
) & { readonly as?: 'a' | 'button' | typeof Link };
export interface StyledButtonProps {
  readonly $appearance?: ButtonAppearance;
  readonly $square?: boolean;
  readonly href?: string;
  readonly target?: string;
  readonly $size?: ButtonSize;
  readonly $iconOnly?: boolean;
  readonly $appearanceStyle?: TextInputProps['appearance'];
  readonly disabled?: boolean;
  readonly stretch?: boolean;
  readonly small?: boolean;
}

export const Button = styled.button<StyledButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: ${({ theme }) => theme.size.x05};
  height: ${({ theme, small }) => (small ? theme.size.x3 : theme.size.x4)};
  ${getTextAppearanceStyles('button10')}
  background-color: transparent;
  border: 0;
  border-radius: ${({ theme, small }) =>
    small ? theme.borderRadius.x05 : theme.borderRadius.x1};
  padding: ${({ theme, $iconOnly }) =>
      $iconOnly ? theme.size.x05 : theme.size.x1}
    ${({ theme }) => theme.size.x1};
  cursor: pointer;
  gap: ${({ theme }) => theme.size.x1};
  flex-shrink: 0;
  transition: all 0.2s ease-in-out;
  font-family: ${({ theme }) => theme.text.font.default};
  text-decoration: none;
  text-align: center;
  user-select: none;
  text-transform: uppercase;
  font-weight: 700;

  :hover,
  :focus {
    outline: none;
  }

  :focus-visible {
    box-shadow: ${({ theme }) => theme.boxShadow.bs3};
  }

  :disabled,
  :disabled:active {
    cursor: not-allowed;
    box-shadow: none;
    background: ${({ theme }) =>
      theme.dark ? theme.color.text : theme.color.neutral_8};
    color: ${({ theme }) => theme.color.neutral_24};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      box-shadow: none;
      background: ${({ theme }) =>
        theme.dark ? theme.color.text : theme.color.neutral_8};
      color: ${({ theme }) => theme.color.neutral_24};

      :hover {
        background: ${({ theme }) =>
          theme.dark ? theme.color.text : theme.color.neutral_8};
      }
    `}

  ${({ stretch }) => stretch && 'width: 100%;'};

  ${({ $square, small }) =>
    $square &&
    css`
      width: ${({ theme }) => (small ? theme.size.x3 : theme.size.x4)};
      padding: ${({ theme }) => theme.size.x1};
    `}

  ${({ $size, $square }) =>
    $size === 'compact' &&
    css`
      height: ${({ theme }) => theme.size.x3};
      padding: ${({ theme }) => theme.size.x05} ${({ theme }) => theme.size.x1};

      ${$square &&
      css`
        padding: ${({ theme }) => theme.size.x05};
        width: ${({ theme }) => theme.size.x3};
      `}
    `}
  ${props => !props.disabled && getAppearanceStyle(props.$appearance)}
`;

const StyledPrimaryButton = css<StyledButtonProps>`
  background-color: ${({ theme, $appearanceStyle }) =>
    getAppearanceColors($appearanceStyle, theme).base};
  color: ${({ theme, $appearanceStyle }) =>
    getAppearanceColors($appearanceStyle, theme).background};
  text-transform: uppercase;

  @media (hover: hover) {
    :hover {
      background-color: ${({ theme, $appearanceStyle }) =>
        getAppearanceColors($appearanceStyle, theme).dark};
    }
  }
`;

const StyledSecondaryButton = css<StyledButtonProps>`
  background-color: ${({ theme, $appearanceStyle }) =>
    getAppearanceColors($appearanceStyle, theme).background};
  color: ${({ theme, $appearanceStyle }) =>
    getAppearanceColors($appearanceStyle, theme).base};
  text-transform: uppercase;

  @media (hover: hover) {
    :hover {
      background-color: ${({ theme, $appearanceStyle }) =>
        getAppearanceColors($appearanceStyle, theme).focus};
    }
  }

  :disabled,
  :disabled:active {
    background-color: ${({ theme }) => theme.color.neutral_8};
    color: ${({ theme }) => theme.color.neutral_24};
    box-shadow: none;
  }
`;

const StyledTertiaryButton = css<StyledButtonProps>`
  background-color: unset;
  color: ${({ theme, $appearanceStyle }) =>
    getAppearanceColors($appearanceStyle, theme).text};
  box-shadow: none;

  @media (hover: hover) {
    :hover {
      background-color: ${({ theme, $appearanceStyle }) =>
        getAppearanceColors($appearanceStyle, theme).background};
    }
  }

  :disabled,
  :disabled:active {
    background: ${({ theme }) => theme.color.neutral_24};
    color: ${({ theme }) => theme.color.white};
    box-shadow: none;
  }
`;

const StyledUnstyledButton = css<StyledButtonProps>`
  padding: 0;
  height: unset;
  width: unset;
  color: ${({ theme, $appearanceStyle }) =>
    getAppearanceColors($appearanceStyle, theme).text};
  box-shadow: none;

  @media (hover: hover) {
    :hover {
      background-color: ${({ theme, $appearanceStyle }) =>
        getAppearanceColors($appearanceStyle, theme).background};
    }
  }

  :disabled,
  :disabled:active {
    background: ${({ theme }) => theme.color.neutral_24};
    color: ${({ theme }) => theme.color.white};
    box-shadow: none;
  }
`;
function getAppearanceStyle(
  appearance?: ButtonAppearance
): typeof StyledPrimaryButton {
  switch (appearance) {
    case ButtonAppearance.Primary: {
      return StyledPrimaryButton;
    }
    case ButtonAppearance.Secondary: {
      return StyledSecondaryButton;
    }
    case ButtonAppearance.Tertiary: {
      return StyledTertiaryButton;
    }
    case ButtonAppearance.None: {
      return StyledUnstyledButton;
    }
    default:
      return StyledPrimaryButton;
  }
}
