import { createContext, useContext } from 'react';
import { Logger } from './logger';

export const LoggerContext = createContext<Logger | undefined>(undefined);

export function useLogger(): Logger {
  const logger = useContext(LoggerContext);

  if (!logger) {
    throw new Error('logger not available');
  }

  return logger;
}
