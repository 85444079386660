import * as React from 'react';
import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { GlobalLogger } from '../../utils/logger/setGlobalLogger';

export class RichTextErrorBoundary extends React.PureComponent<
  PropsWithChildren,
  { error?: boolean }
> {
  public readonly state = { error: false };

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    GlobalLogger.error(error.message, error.name);
    this.setState({ error: true });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    return error ? <FormattedMessage id="richtext.error" /> : children;
  }
}
